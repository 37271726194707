import { gql, GraphQLClient } from 'graphql-request';
import toastr from 'toastr';

const main = async (values) => {
  console.log({ values });
  let email;
  let token;
  let name;
  let id;

  toastr.options = {
    positionClass: 'toast-top-right',
    hideDuration: 300,
    timeOut: 10000,
    closeButton: true
  };
  toastr.clear();

  try {
    const graphQLClient = new GraphQLClient(process.env.NEXT_PUBLIC_API_ENDPOINT);

    const mutation = gql`
      mutation createUser(
        $firstName: String!
        $lastName: String!
        $email: String!
        $password: String!
        $role: String!
        $newsletter: Boolean!
        $teamToken: String
        $referralCode: String
      ) {
        createUser(
          input: {
            firstName: $firstName
            lastName: $lastName
            email: $email
            password: $password
            role: $role
            newsletter: $newsletter
            teamToken: $teamToken
            referralCode: $referralCode
          }
        ) {
          _id
          firstName
          lastName
          password
          email
          role
          token
          newsletter
        }
      }
    `;
    const variables = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      role: 'Student' || values.role,
      teamToken: values.token,
      referralCode: values.referralCode,
      newsletter: values.newsletter || false
    };
    const { createUser } = await graphQLClient.request(mutation, variables);
    if (createUser) {
      toastr.success(`${createUser.email} registered successfully`);
      email = createUser.email;
      token = createUser.token;
      name = `${createUser.firstName} ${createUser.lastName}`;
      id = createUser._id;
    }

    return { email, token, name, id };
  } catch (error) {
    let errors;
    if (error && error.response && error.response.errors && Array.isArray(error.response.errors)) {
      errors = error.response.errors;
    }
    if (errors) {
      errors.map((err) => {
        toastr.error(err.message.message || err.message);
      });
    }
  }
};

export default main;

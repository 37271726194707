/* eslint-disable no-empty-pattern */
import { Form, Formik } from 'formik';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import closeIcon from 'public/images/close-icon.svg';
import logoIcon from 'public/images/logo-icon.png';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import * as Yup from 'yup';

import { PrimarySubmitButton, TransparentRoundButton } from '@/design-system/buttons';

import main from '../../utils/signup';
import { MyCheckbox, MyTextInput } from '../shared/form-inputs';

export const StyledAuthLayout = styled.div`
  display: grid;
  grid-template-columns: 6fr 5fr;
  grid-template-rows: none;
  height: 100vh;
  width: 100%;
  .first-col {
    background-color: ${(props) => props.theme.primaryText};
    grid-row: unset;
  }
  .second-col {
    background: ${(props) => props.theme.authBg};
  }
  .overlay {
    display: grid;
    grid-template-columns: 5fr 4fr;
    grid-template-rows: none;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
  }
  .form-section {
    width: 100%;
    padding: 30px 105px 30px 55px;
    background: ${(props) => props.theme.primaryText};
    box-shadow: -6px 6px 40px rgba(172, 0, 93, 0.2);
    border-radius: 25px 0px 0px 25px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .form-title {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: capitalize;
      color: ${(props) => props.theme.cardBackground};
      opacity: 0.8;
    }
  }
  .brand-section {
    background-image: linear-gradient(
        180deg,
        rgba(26, 32, 43, 0.78) 0%,
        rgba(33, 33, 33, 0.9) 45.03%
      ),
      url('/images/auth-bg.jpg');
    background-size: contain;
    padding: 60px 45px;
    display: flex;
    flex-direction: column;
    grid-row: unset;
    justify-content: space-between;
  }
  .logo {
    align-self: unset;
    margin-bottom: 0;
  }
  .logo a {
    display: flex;
    align-items: center;
  }
  .logo > a > img {
    height: 60px;
    width: 60px;
  }
  .logo > a > span {
    margin-left: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: ${(props) => props.theme.primaryText};
  }
  .description {
    align-self: unset;
    text-align: left;
  }
  .desc-title {
    font-family: 'DM Serif Display', serif;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: ${(props) => props.theme.primaryText};
    letter-spacing: 0.08em;
    text-transform: capitalize;
    max-width: 300px;
  }
  .desc-subtitle {
    margin-top: 30px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: ${(props) => props.theme.primaryText};
    opacity: 0.8;
    max-width: 250px;
  }
  .desc-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    display: inherit;
  }
  .desc-action > span {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.04em;
    color: ${(props) => props.theme.primaryText};
    margin-bottom: 15px;
  }
  .close-section {
    position: absolute;
    top: 30px;
    right: 30px;
    background: rgba(255, 255, 255, 0.8);
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
  }
  .close-action > img {
    width: 17px;
    height: 17px;
  }
  .social {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
  .social > a {
    margin-right: 25px;
  }
  .social > a > img {
    width: 60px;
    height: 80px;
  }
  .dividers {
    display: flex;
    align-items: center;
  }
  .line {
    width: 40%;
    height: 0;
    border: 1.5px solid rgba(33, 33, 33, 0.5);
  }
  .divider-text {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: lowercase;
    color: ${(props) => props.theme.cardBackground};
    opacity: 0.8;
    padding: 0 15px;
  }
  .form-action {
    display: grid;
    place-items: center;
    margin-top: 95px;
  }
  .terms,
  .terms > a {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: ${(props) => props.theme.cardBackground};
    opacity: 0.9;
    text-align: center;
    margin-top: 10px;
    max-width: 261px;
  }
  .form-action-login {
    display: none;
  }
  @media (max-width: 1024px) {
    grid-template-rows: 6fr 5fr;
    grid-template-columns: none;
    min-height: 100vh;
    height: auto;
    .first-col {
      grid-row: 2;
    }
    .second-col {
      background: ${(props) => props.theme.primaryText};
    }
    .overlay {
      display: grid;
      grid-template-rows: 2fr 4fr;
      grid-template-columns: none;
      position: absolute;
      top: 0px;
      width: 100%;
      height: auto;
    }
    .brand-section {
      grid-row: 1;
      padding: 37px 40px 15px 40px;
      justify-content: flex-start;
      background-size: cover;
      background-position: center;
      overflow-x: hidden;
    }
    .desc-action {
      display: none;
    }
    .description {
      align-self: center;
      text-align: center;
    }
    .logo {
      align-self: center;
      margin-bottom: 20px;
    }
    .close-section {
      top: 40px;
      right: 40px;
    }
    .desc-subtitle {
      margin-top: 5px;
    }
    .form-section {
      border-radius: 0px 0px 25px 25px;
      margin-left: 26px;
      margin-right: 26px;
      margin-bottom: 10px;
      padding: 30px 36px;
      width: calc(100% - 52px);
      z-index: 300;
      margin-top: -80px;
      overflow-x: hidden;
      .form-title {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .form-action {
      margin-top: 50px;
    }
    .form-action-login {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.headerBackground};
      opacity: 0.8;
      margin-bottom: 15px;
    }
    .form-action-login > a {
      color: ${(props) => props.theme.primaryLink};
      font-weight: bold;
    }
    .logo a {
      display: flex;
      align-items: center;
    }
    .logo > a > img {
      height: 30px;
      width: 30px;
    }
    .logo > a > span {
      margin-left: 5px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: ${(props) => props.theme.primaryText};
    }
    .desc-title {
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0.07em;
      max-width: 297px;
    }
    .desc-subtitle {
      margin-top: 5px;
      font-size: 12px;
      line-height: 18px;
      max-width: 271px;
    }
    .social > a > img {
      width: 50px;
      height: auto;
    }
    .divider-text {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const Signup = () => {
  const [, setCookie] = useCookies([]);
  const router = useRouter();
  const [, setTeamToken] = useState(null);
  const [, setEmailToken] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (router?.query?.token && router?.query?.email) {
      setTeamToken(router?.query?.token);
      setEmailToken(router?.query?.email);
    }
  });

  return (
    <StyledAuthLayout>
      <div className="first-col"></div>
      <div className="second-col"></div>
      <div className="overlay">
        <div className="form-section">
          <div>
            <div className="form-title">Create an Account</div>
            <div className="social">
              {/* <Link href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/google`}>
                <a>
                  <Image src={googleIcon} alt="Google Icon" placeholder="blur" />
                </a>
              </Link>
              <Link href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/twitter`}>
                <a>
                  <Image src={twitterIcon} alt="Twitter Icon" placeholder="blur" />
                </a>
              </Link>
              <Link href={`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/github`}>
                <a>
                  <Image src={githubIcon} alt="Github Icon" placeholder="blur" />
                </a>
              </Link> */}
            </div>
            <div className="dividers">
              <div className="line" />
              {/* <div className="divider-text">or</div> */}
              <div className="line" />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: `${router?.query?.email ? router?.query?.email : ''}`,
                password: '',
                jobType: '', // added for our select
                newsletter: false // added for our select
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().min(3, 'Must be 3 characters or more').required('Required'),
                lastName: Yup.string().min(3, 'Must be 3 characters or more'),
                email: Yup.string().email('Invalid email address').required('Required'),
                password: Yup.string().min(6, 'Must be 6 characters or more').required('Required')
              })}
              onSubmit={async (values, {}) => {
                setDisableButton(true);
                try {
                  values.token = router?.query?.token;
                  values.referralCode = router?.query?.ref;
                  console.log(values);
                  let date = new Date();
                  date.setTime(date.getTime() + 60 * 60 * 1000 * 1); // token expires in 1 hour
                  const { email, token, name, id } = await main(values);
                  global.analytics.track('User Signed Up', {
                    name,
                    email: values.email
                  });
                  global.analytics.identify(id, {
                    name,
                    email: values.email
                  });
                  setCookie('email', email, { path: '/' });
                  setCookie('token', token, { path: '/', expires: date });
                  setCookie('isLoggedIn', true, { path: '/' });
                  router.push('/');
                } catch (err) {
                  console.log({ err });
                  setDisableButton(false);
                }
              }}>
              {({ isSubmitting, values }) => (
                <>
                  <Form>
                    <MyTextInput
                      label="First Name"
                      name="firstName"
                      type="text"
                      id="firstName"
                      placeholder="First Name"
                    />
                    <MyTextInput
                      label="Last Name"
                      name="lastName"
                      type="text"
                      id="lastName"
                      placeholder="Last Name"
                    />
                    <MyTextInput
                      label="Email"
                      name="email"
                      type="email"
                      disabled={router?.query?.email}
                      id="email"
                      placeholder="you@email.com"
                    />
                    <MyTextInput
                      label="Password"
                      name="password"
                      id="password"
                      type="password"
                      placeholder="***********"
                    />
                    <MyCheckbox name="newsletter" checked={values.newsletter}>
                      {' '}
                      Subscribe to Our Newsletter
                    </MyCheckbox>

                    <div className="form-action">
                      <div className="form-action-login">
                        Already Signed Up?{' '}
                        <Link href="/login">
                          <a>Log In</a>
                        </Link>
                      </div>
                      <PrimarySubmitButton
                        medium
                        type="submit"
                        disabled={isSubmitting || disableButton}>
                        Get Started
                      </PrimarySubmitButton>
                      <div className="terms">
                        By Clicking on this button, you agree to our{' '}
                        <Link href="/terms">
                          <a>Terms and Conditions</a>
                        </Link>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
        <div className="brand-section">
          <Link href="/">
            <a className="close-section">
              <Image src={closeIcon} alt="Close Icon" />
            </a>
          </Link>
          <div className="logo">
            <Link href="/">
              <a>
                <Image src={logoIcon} alt="Logo" placeholder="blur" />
                <span>BrytaHub</span>
              </a>
            </Link>
          </div>
          <div className="description">
            <div className="desc-title">Unlock Learning and Upgrade your Skills</div>
            <div className="desc-subtitle">Create an account to join the community of learners</div>
          </div>
          <div className="desc-action">
            <span>Already have an account?</span>
            <Link href="/login" passHref>
              <TransparentRoundButton medium>Log In</TransparentRoundButton>
            </Link>
          </div>
        </div>
      </div>
    </StyledAuthLayout>
  );
};

export default Signup;
